<template>
  <transition name="fade" mode="out-in">
    <keep-alive v-if="key != '/'" :include="keepAlive">
      <router-view :key="key"></router-view>
    </keep-alive>
    <div v-else class="welcome">
      <img :src="welcomeImg" alt="">
    </div>
  </transition>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Main",
  computed: {
    ...mapState(["keepAlive"]),
    key() {
      return this.$route.path;
    },
  },
  data(){
    return{
      welcomeImg: require("@/assets/img/welcome-img.png")
    }
  }
};
</script>

<style scoped lang="scss">
.container {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;

  .layout-title-wrap {
    cursor: pointer;
    width: 240px;
    background: #4f75f3;
    height: 100px;
    padding-top: 15px;

    .layout-title {
      font-size: 18px;
      color: #fff;
      line-height: 1.4;
      text-align: center;
    }

    .layout-title-info {
      font-size: 12px;
      color: #9bafee;
      letter-spacing: -1px;
      line-height: 1.4;
    }
  }
  .layout-title-wrap-small {
    cursor: pointer;
    background: #3c62de;
    height: 60px;
    overflow: hidden;
  }

  .layout-aside {
    position: relative;
    overflow: hidden;
    transition: all 0.25s;

    .menu-wrap {
      position: absolute;
      //top: 120px;
      bottom: 0;
      left: 0;
      width: 100%;
      overflow-y: scroll;
      background: #3056d4;

      &::-webkit-scrollbar {
        display: none; /* Chrome Safari */
      }
    }
  }

  .layout-header {
    height: 110px !important;
    padding-left: 0;
    padding-right: 0;
  }
  .layout-main-wrap {
    padding: 0;
    position: relative;
    overflow: hidden;
    .copyright {
      position: absolute;
      left: 0;
      bottom: 10px;
      right: 0;
      height: 20px;
      text-align: center;
      i,
      span {
        font-size: 14px;
        color: #999;
        line-height: 20px;
      }
    }
    .layout-main {
      position: absolute;
      top: 10px;
      left: 10px;
      right: 0;
      bottom: 40px;
      overflow: hidden;
      .router-wrap {
        background: #fff;
        padding: 12px;
        height: 100%;
        box-sizing: border-box;
        overflow-x: hidden;
        overflow-y: scroll;
      }
    }
  }
}

.welcome{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  img{
    width: 703px;
    height: 724px;
  }
}

//路由过渡动画
.fade-enter-active,
.fade-leave-active {
  transition: all 0.2s ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
.logo-img {
  display: block;
  width: 100px;
  margin: 0 auto 10px;
}
.logo-img-small {
  display: block;
  width: 55px;
  margin: 15px auto 0;
}
</style>
